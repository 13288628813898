@font-face {
    font-family: 'Source Code Pro';
    src: local('Source Code Pro Black Italic'), local('SourceCodePro-BlackItalic'),
        url('assets/fonts/SourceCodePro-BlackItalic.woff2') format('woff2'),
        url('assets/fonts/SourceCodePro-BlackItalic.woff') format('woff'),
        url('assets/fonts/SourceCodePro-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: local('Source Code Pro Bold'), local('SourceCodePro-Bold'),
        url('assets/fonts/SourceCodePro-Bold.woff2') format('woff2'),
        url('assets/fonts/SourceCodePro-Bold.woff') format('woff'),
        url('assets/fonts/SourceCodePro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: local('Source Code Pro ExtraBold Italic'), local('SourceCodePro-ExtraBoldItalic'),
        url('assets/fonts/SourceCodePro-ExtraBoldItalic.woff2') format('woff2'),
        url('assets/fonts/SourceCodePro-ExtraBoldItalic.woff') format('woff'),
        url('assets/fonts/SourceCodePro-ExtraBoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: local('Source Code Pro Bold Italic'), local('SourceCodePro-BoldItalic'),
        url('assets/fonts/SourceCodePro-BoldItalic.woff2') format('woff2'),
        url('assets/fonts/SourceCodePro-BoldItalic.woff') format('woff'),
        url('assets/fonts/SourceCodePro-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: local('Source Code Pro ExtraLight'), local('SourceCodePro-ExtraLight'),
        url('assets/fonts/SourceCodePro-ExtraLight.woff2') format('woff2'),
        url('assets/fonts/SourceCodePro-ExtraLight.woff') format('woff'),
        url('assets/fonts/SourceCodePro-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: local('Source Code Pro Medium'), local('SourceCodePro-Medium'),
        url('assets/fonts/SourceCodePro-Medium.woff2') format('woff2'),
        url('assets/fonts/SourceCodePro-Medium.woff') format('woff'),
        url('assets/fonts/SourceCodePro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: local('Source Code Pro Light'), local('SourceCodePro-Light'),
        url('assets/fonts/SourceCodePro-Light.woff2') format('woff2'),
        url('assets/fonts/SourceCodePro-Light.woff') format('woff'),
        url('assets/fonts/SourceCodePro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: local('Source Code Pro Light Italic'), local('SourceCodePro-LightItalic'),
        url('assets/fonts/SourceCodePro-LightItalic.woff2') format('woff2'),
        url('assets/fonts/SourceCodePro-LightItalic.woff') format('woff'),
        url('assets/fonts/SourceCodePro-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: local('Source Code Pro ExtraBold'), local('SourceCodePro-ExtraBold'),
        url('assets/fonts/SourceCodePro-ExtraBold.woff2') format('woff2'),
        url('assets/fonts/SourceCodePro-ExtraBold.woff') format('woff'),
        url('assets/fonts/SourceCodePro-ExtraBold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: local('Source Code Pro SemiBold'), local('SourceCodePro-SemiBold'),
        url('assets/fonts/SourceCodePro-SemiBold.woff2') format('woff2'),
        url('assets/fonts/SourceCodePro-SemiBold.woff') format('woff'),
        url('assets/fonts/SourceCodePro-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: local('Source Code Pro Medium Italic'), local('SourceCodePro-MediumItalic'),
        url('assets/fonts/SourceCodePro-MediumItalic.woff2') format('woff2'),
        url('assets/fonts/SourceCodePro-MediumItalic.woff') format('woff'),
        url('assets/fonts/SourceCodePro-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: local('Source Code Pro Regular'), local('SourceCodePro-Regular'),
        url('assets/fonts/SourceCodePro-Regular.woff2') format('woff2'),
        url('assets/fonts/SourceCodePro-Regular.woff') format('woff'),
        url('assets/fonts/SourceCodePro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: local('Source Code Pro ExtraLight Italic'), local('SourceCodePro-ExtraLightItalic'),
        url('assets/fonts/SourceCodePro-ExtraLightItalic.woff2') format('woff2'),
        url('assets/fonts/SourceCodePro-ExtraLightItalic.woff') format('woff'),
        url('assets/fonts/SourceCodePro-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: local('Source Code Pro SemiBold Italic'), local('SourceCodePro-SemiBoldItalic'),
        url('assets/fonts/SourceCodePro-SemiBoldItalic.woff2') format('woff2'),
        url('assets/fonts/SourceCodePro-SemiBoldItalic.woff') format('woff'),
        url('assets/fonts/SourceCodePro-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: local('Source Code Pro Italic'), local('SourceCodePro-Italic'),
        url('assets/fonts/SourceCodePro-Italic.woff2') format('woff2'),
        url('assets/fonts/SourceCodePro-Italic.woff') format('woff'),
        url('assets/fonts/SourceCodePro-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Source Code Pro';
    src: local('Source Code Pro Black'), local('SourceCodePro-Black'),
        url('assets/fonts/SourceCodePro-Black.woff2') format('woff2'),
        url('assets/fonts/SourceCodePro-Black.woff') format('woff'),
        url('assets/fonts/SourceCodePro-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

