@tailwind base;
@tailwind components;
@tailwind utilities;

@import "sourceCodePro.css";
.monospace {
  font-family: "Source Code Pro" !important;
}
@import "global/sidebar.scss";
/* montserrat-300 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 300;
  src: url("assets/fonts/montserrat-v25-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("assets/fonts/montserrat-v25-latin-300.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/montserrat-v25-latin-300.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("assets/fonts/montserrat-v25-latin-300.woff") format("woff"),
    /* Modern Browsers */ url("assets/fonts/montserrat-v25-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("assets/fonts/montserrat-v25-latin-300.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-regular - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 400;
  src: url("assets/fonts/montserrat-v25-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("assets/fonts/montserrat-v25-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/montserrat-v25-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("assets/fonts/montserrat-v25-latin-regular.woff") format("woff"),
    /* Modern Browsers */ url("assets/fonts/montserrat-v25-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("assets/fonts/montserrat-v25-latin-regular.svg#Montserrat")
      format("svg"); /* Legacy iOS */
}
/* montserrat-500 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 500;
  src: url("assets/fonts/montserrat-v25-latin-500.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("assets/fonts/montserrat-v25-latin-500.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/montserrat-v25-latin-500.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("assets/fonts/montserrat-v25-latin-500.woff") format("woff"),
    /* Modern Browsers */ url("assets/fonts/montserrat-v25-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("assets/fonts/montserrat-v25-latin-500.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-600 - latin */
@font-face {
  font-family: "Montserrat";
  font-style: normal;
  font-weight: 600;
  src: url("assets/fonts/montserrat-v25-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("assets/fonts/montserrat-v25-latin-600.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/montserrat-v25-latin-600.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("assets/fonts/montserrat-v25-latin-600.woff") format("woff"),
    /* Modern Browsers */ url("assets/fonts/montserrat-v25-latin-600.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("assets/fonts/montserrat-v25-latin-600.svg#Montserrat") format("svg"); /* Legacy iOS */
}
/* montserrat-italic - latin */
@font-face {
  font-family: "Montserrat";
  font-style: italic;
  font-weight: 400;
  src: url("assets/fonts/montserrat-v25-latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""),
    url("assets/fonts/montserrat-v25-latin-italic.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("assets/fonts/montserrat-v25-latin-italic.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("assets/fonts/montserrat-v25-latin-italic.woff") format("woff"),
    /* Modern Browsers */ url("assets/fonts/montserrat-v25-latin-italic.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("assets/fonts/montserrat-v25-latin-italic.svg#Montserrat")
      format("svg"); /* Legacy iOS */
}

html,
body,
#root,
.app {
  position: relative;
  height: 100%;
  width: 100%;
  font-family: "Montserrat";
  overflow: hidden;
}
.content {
  overflow-y: auto;
}
.app {
  display: flex;
  position: relative;
}
.dragging {
  background-color: rgba(172, 255, 47, 0.27) !important;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-track {
  background-color: #0c0b0b29;
}

::-webkit-scrollbar-thumb {
  background-color: #252830;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

.money::after {
  content: "€";
  margin-left: 4px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

#scanner {
  animation: scann infinite ease-in-out 2s;
}

@keyframes scann {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(144px);
  }
  100% {
    transform: translatey(0px);
  }
}
/* LOADER */
.loader {
  transform: rotateZ(45deg);
  perspective: 1000px;
  border-radius: 50%;
  width: 48px;
  height: 48px;
}
.loader:before,
.loader:after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: inherit;
  height: inherit;
  border-radius: 50%;
  transform: rotateX(70deg);
  animation: 1s spin linear infinite;
}
.loader:after {
  transform: rotateY(70deg);
  animation-delay: 0.4s;
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotateZ(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}

@keyframes rotateccw {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }
  100% {
    transform: translate(-50%, -50%) rotate(-360deg);
  }
}

@keyframes spin {
  0%,
  100% {
    box-shadow: 0.2em 0px 0 0px currentcolor;
  }
  12% {
    box-shadow: 0.2em 0.2em 0 0 currentcolor;
  }
  25% {
    box-shadow: 0 0.2em 0 0px currentcolor;
  }
  37% {
    box-shadow: -0.2em 0.2em 0 0 currentcolor;
  }
  50% {
    box-shadow: -0.2em 0 0 0 currentcolor;
  }
  62% {
    box-shadow: -0.2em -0.2em 0 0 currentcolor;
  }
  75% {
    box-shadow: 0px -0.2em 0 0 currentcolor;
  }
  87% {
    box-shadow: 0.2em -0.2em 0 0 currentcolor;
  }
}

/* CHECK */

@keyframes transparant {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.check,
.cross {
  animation: transparant 2s;
}

.check-group,
.cross-group {
  animation: 0.32s ease-in-out 1.03s little-pop;
  transform-origin: center;
}

.check-group .check,
.cross-group .cross__path {
  animation: 0.34s cubic-bezier(0.65, 0, 1, 1) 0.8s forwards check;
  stroke-dasharray: 0, 75px;
  stroke-linecap: round;
  stroke-linejoin: round;
}

.check-group .outline-circle,
.cross-group .outline-circle {
  animation: 0.38s ease-in outline;
  transform: rotate(0deg);
  transform-origin: center;
}

.check-group .white-circle,
.cross-group .white-circle {
  animation: 0.35s ease-in 0.35s forwards circle;
  transform: none;
  transform-origin: center;
}

@keyframes outline {
  from {
    stroke-dasharray: 0, 345.576px;
  }
  to {
    stroke-dasharray: 345.576px, 345.576px;
  }
}
@keyframes circle {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0);
  }
}
@keyframes check {
  from {
    stroke-dasharray: 0, 75px;
  }
  to {
    stroke-dasharray: 75px, 75px;
  }
}
@keyframes little-pop {
  from {
    transform: scale(1);
  }
  50% {
    transform: scale(1.09);
  }
  to {
    transform: scale(1);
  }
}

/* CROSS */

.cross__svg {
  border-radius: 50%;
  display: block;
}

.cross__circle {
  animation: 0.38s ease-in outline;
  transform: rotate(0deg);
  transform-origin: center;
}

.cross__path {
  transform-origin: 50% 50% 0;
  &--right {
    animation: 0.3s ease 0.3s normal forwards 1 running stroke;
  }
  &--left {
    animation: 0.3s ease 0.3s normal forwards 1 running stroke;
  }
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes rotate {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.rainbow {
  background: linear-gradient(
    124deg,
    rgb(2, 207, 207),
    rgb(207, 2, 200),
    rgb(2, 207, 207),
    rgb(207, 2, 200),
    rgb(2, 207, 207),
    rgb(207, 2, 200)
  );
  background-size: 400% 400%;
  animation: rainbow 5s ease-in-out infinite;
}

@keyframes rainbow {
  0% {
    background-position: 0% 82%;
  }
  50% {
    background-position: 100% 19%;
  }
  100% {
    background-position: 0% 82%;
  }
}
