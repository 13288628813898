.hamburger {
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 0.7;
}
.hamburger.is-active:hover {
  opacity: 0.7;
}

.hamburger-box {
  width: 30px;
  height: 15px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  width: 30px;
  height: 2px;
  margin-top: 3px;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;

  &.b1 {
    top: 0%;
  }
  &.b2 {
    top: 50%;
  }
  &.b3 {
    top: 100%;
  }
}

.is-active .b1 {
  transform: translate3d(-9px, 0, 0) rotate(-45deg) scale(0.77, 1);
}

.is-active .b3 {
  transform: translate3d(-9px, 0, 0) rotate(45deg) scale(0.77, 1);
}
